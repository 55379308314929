import React from 'react';
import createReactClass from 'create-react-class';
import Logo from "../blocks/logo";
import make_logo from '../../../images/make_logo.svg';
import '../../../styles/templates/footer.scss'
import logo from "../../../images/logo.svg";

const Header = createReactClass({

    getInitialState() {
        return {};
    },

    statics: {
        getDerivedStateFromProps() {
            return {}
        },
    },

    componentDidMount() {
    },

    render() {
        return (
            <div className='container'>
                <footer>
                    <div className='footer-logo'>
                        <Logo/>
                    </div>
                    <a className='make-link' href='https://makeagency.ru/' target='_blank'>
                        <div className='make-link__logo'>
                            <img src={make_logo} alt='Мэйк'/>
                        </div>
                        <div className='make-link__text'>
                            Мэйк, 2020
                        </div>
                    </a>
                </footer>
            </div>
        );

    }
});

export default Header;
