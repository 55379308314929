import React from 'react';
import createReactClass from 'create-react-class';
import Logo from "../blocks/logo";
import '../../../styles/templates/header.scss'

const Header = createReactClass({

    getInitialState() {
        return {};
    },

    statics: {
        getDerivedStateFromProps() {
            return {}
        },
    },

    componentDidMount() {
    },

    render() {
        return (
            <div className='container'>
                <header>
                    <Logo/>
                </header>
            </div>
        );

    }
});

export default Header;
