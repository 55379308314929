import React from 'react';
import createReactClass from 'create-react-class';
import '../../../styles/blocks/base-button.scss';

const BaseButton = createReactClass({

    getInitialState() {
        return {};
    },
    statics: {
        getDerivedStateFromProps() {
            return {}
        },
    },

    componentDidMount() {
    },


    render() {

        return (
            <button className='base-button-transparent base-button-transparent--blue' onClick={() => this.props.onClickMethod()}>
                <div className='base-button-transparent--decoration-right'></div>
                <div className='base-button-transparent--decoration-left'></div>
                {this.props.text}
            </button>
        );

    }
});

export default BaseButton;
