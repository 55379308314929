import React from 'react';
import createReactClass from 'create-react-class';
import BigTitle from "../blocks/big_title";
import BaseButton from "../blocks/base_button";
import InputMask from 'react-input-mask';
import jquery from 'jquery';
import '../../../styles/templates/promocode_block.scss'

window.$ = jquery;


const PromocodeBlock = createReactClass({

    getInitialState() {
        return {
            phone: '',
            codeSms: '',
            promocodeFormWrapperFlag: true,
            promocodeFormFlag: false,
            promocodeFormButtonFlag: true,
            successFlag: false,
            promocodeTimerFlag: true,
            promocode: '',
            timer: ''
        };
    },

    statics: {
        getDerivedStateFromProps() {
            return {}
        },
    },

    componentDidMount() {
    },

    setPhone(event) {
        this.setState({
                phone: event.target.value
            }
        );
    },

    submitFormGetCode() {
        if (this.state.phone && this.state.phone.length >= 17) {
            window.$('#phone-input').removeClass('error');
            var self = this;
            self.setState({
                error_phone: false
            });

            window.$.ajax({
                type: "POST",
                dataType: 'json',
                url: 'https://api.makearena.ru/promocodes/get_sms_for_promocode',
                data: {
                    stock_id: 2,
                    phone: self.state.phone
                },
                success: function (data) {
                    self.setState({promocodeFormFlag: true, promocodeFormButtonFlag: false});
                    self.initSmsTimer();
                },
                error: function (data) {
                    window.$('#phone-input').addClass('error');
                    self.setState({
                        error_phone: JSON.parse(data.responseText).error
                    })
                }
            });
        } else {
            window.$('#phone-input').addClass('error')
        }

    },
    setCodeSms(event) {
        this.setState({
                codeSms: event.target.value
            }
        );
    },

    checkCodeSms() {
        var self = this;
        window.$.ajax({
            type: "POST",
            dataType: 'json',
            url: 'https://api.makearena.ru/promocodes/generate_promocode',
            data: {
                code_sms: self.state.codeSms,
                phone: self.state.phone
            },
            success: function (data) {
                window.$('#input-promocode').removeClass('error');
                window.$('#phone-input').removeClass('error');
                self.setState({
                    promocode: data.promocode,
                    successFlag: true,
                    promocodeFormWrapperFlag: false
                });
            },
            error: function (data) {
                window.$('#input-promocode').addClass('error');
                window.$('#phone-input').addClass('error');
            }
        });
    },

    renderPromocodeTimer() {
        if (this.state.promocodeTimerFlag) {
            return (
                <div className='promocode-block__timer-block'>
                    <span>Отправить промокод повторно через</span> <span
                    className='promocode-block__timer'>{this.state.timer}</span>
                </div>
            )
        } else {
            return (
                <div className='promocode-block__resent-sms' onClick={this.submitFormGetCode}>
                    Я НЕ ПОЛУЧИЛ ПРОМОКОД
                </div>
            )
        }
    },

    initSmsTimer() {
        var time = 180;
        var self = this;
        clearInterval(this.timer_interval);
        this.setState({
            promocodeTimerFlag: true
        });

        this.timer_interval = setInterval(function () {
            time -= 1;
            self.setState({
                timer: new Date(time * 1000).toISOString().substr(14, 5)
            });
            if (time <= 0) {
                self.setState({
                    promocodeTimerFlag: false
                });
                clearInterval(this.timer_interval);

            }
        }, 1000)
    },

    renderSmsForm() {
        if (this.state.promocodeFormFlag) {
            return (
                <div className='promocode-block__form promocode-block__form--bottom'>
                    <div className="promocode-block__form-field-wrapper">
                        <input
                            type='text'
                            className='promocode-block__form-input' placeholder='Введите код подтверждения'
                            value={this.state.codeSms}
                            onChange={this.setCodeSms}
                            id='input-promocode'
                        />

                        {this.renderPromocodeTimer()}
                    </div>
                    <div className="promocode-block__form-field-wrapper">
                        <BaseButton text='Отправить' onClickMethod={this.checkCodeSms}/>
                    </div>
                </div>
            )
        } else {
            return ''
        }

    },

    renderSuccessBlock() {
        if (this.state.successFlag) {
            return (
                <div className='success-block'>
                    <div className='success-block__wrapper'>
                        <div className='success-block__title'>
                            ваш промокод <span>{this.state.promocode}</span>
                        </div>

                        <div className='success-block__description'>
                            <span>Осталось_сделать</span> скриншот экрана телефона
                            с промокодом и показать его администратору
                            при посещении клуба!
                        </div>
                    </div>
                </div>
            )
        } else {
            return ''
        }
    },

    editPhone() {
        this.setState({
            phone: '',
            codeSms: '',
            promocodeFormWrapperFlag: true,
            promocodeFormFlag: false,
            promocodeFormButtonFlag: true,
            successFlag: false,
            promocode: '',
            error_phone: false
        })
    },

    renderPhoneDescription() {
        if (this.state.promocodeFormFlag) {
            return (
                <div className='promocode-block__form-phone-edit-link' onClick={() => this.editPhone()}>
                    ИЗМЕНИТЬ НОМЕР
                </div>
            )

        }
        else if(this.state.error_phone){
            return (
                <div className='promocode-block__form-phone-edit-link'>
                    {this.state.error_phone}
                </div>
            )
        }
        else {
            return (
                <div className='promocode-block__form-input-description'>
                    Мы отправим промокод в смс
                </div>
            )

        }
    },

    renderPromocodeFormWrapper() {
        if (this.state.promocodeFormWrapperFlag) {
            return (
                <div className='promocode-block__form-wrapper'>
                    <div className='promocode-block__form'>
                        <div className="promocode-block__form-field-wrapper">
                            <InputMask
                                mask='+7-(999)-999-9999'
                                maskChar=''
                                type='tel'
                                className='promocode-block__form-input' placeholder='Ваш номер телефона'
                                value={this.state.phone}
                                onChange={this.setPhone}
                                id='phone-input'
                                required
                            />

                            {this.renderPhoneDescription()}
                        </div>
                        <div className="promocode-block__form-field-wrapper">
                            {this.state.promocodeFormButtonFlag ? (
                                <BaseButton text='Получить промокод' onClickMethod={this.submitFormGetCode}/>
                            ) : ''}
                        </div>
                    </div>

                    {this.renderSmsForm()}
                </div>
            )
        } else {
            return ''
        }
    },

    render() {
        return (
            <div className='container'>
                <div className='promocode-block'>
                    <div className='promocode-block__content'>
                        <div className='promocode-block__title-wrapper'>
                            <div className='promocode-block__title-block'>
                                <BigTitle title={'+1 ЧАС БЕСПЛАТНО'}/>
                                <div className='promocode-block__title-description'>
                                    По промокоду при первом
                                    <br/>
                                    посещении клуба
                                </div>
                            </div>
                        </div>


                        {this.renderPromocodeFormWrapper()}
                        {this.renderSuccessBlock()}

                    </div>
                </div>
            </div>
        );

    }
});

export default PromocodeBlock;
