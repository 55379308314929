import React from 'react';
import createReactClass from 'create-react-class';
import logo from '../../../images/logo.svg';
import '../../../styles/blocks/base-logo.scss';

const Logo = createReactClass({

    getInitialState() {
        return {};
    },

    statics: {
        getDerivedStateFromProps() {
            return {}
        },
    },

    componentDidMount() {
    },

    render() {
        return (
            <div className='base-logo'>
                <img src={logo} alt='Мэйк Арена'/>
            </div>
        );

    }
});

export default Logo;
