import React from 'react';
import createReactClass from 'create-react-class';
import Header from '../components/templates/header'
import PromocodeBlock from '../components/templates/promocode_block'
import MapBlock from '../components/templates/map_block'
import Footer from '../components/templates/footer'

const App = createReactClass({

    getInitialState() {
        return {
            location: '/'
        }
    },

    componentDidMount() {

    },

    componentDidUpdate() {

    },

    render() {

        return (
            <div className="App">
                <Header/>
                <PromocodeBlock/>
                <MapBlock/>
                <Footer/>
            </div>
        );
    }

});

export default App;
