import React from 'react';
import createReactClass from 'create-react-class';
import '../../../styles/blocks/big_title.scss'

const BigTitle = createReactClass({

    getInitialState() {
        return {

        };
    },

    statics: {
        getDerivedStateFromProps() {
            return {}
        },
    },

    componentDidMount() {
    },

    render() {
        return (
            <h2 className='big-title'>
                {this.props.title}
            </h2>
        );

    }
});

export default BigTitle;
